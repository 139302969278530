import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Fade } from "react-awesome-reveal"
import { Link } from "gatsby"

import Banner from "../components/banner"

const ThankYou = () => (
	<Layout isThankYouPage={ true }>
		<SEO title="Dziękujemy za wiadomość" />
		<Banner title="Dziękujemy za wiadomość" />
        <section className="thank-you-text">
            <div className="container">
                <Fade delay={150} direction={"top"} triggerOnce>
                    <div className="row">
                        <div className="col-12 thank-you-text__holder">
                            <p className="thank-you-text__text">
                                Twoja wiadmość została wysłana. Skontaktujemy się z Tobą tak szybko jak to będzie możliwe.<br />
                                Miłego dnia!
                            </p>
                            <Link
                                to="/"
                                className="btn thank-you-text__btn"
                            >
                                Strona główna
                            </Link>
                        </div>
                    </div>
                </Fade>
            </div>
        </section>
	</Layout>
)

export default ThankYou
